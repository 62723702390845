<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Admin functions</h3>

	<p>This section of the User Guide includes information for system admins of <span v-html="app_name"/>.</p>

</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	